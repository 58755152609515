<template>
  <div>
    <div class="search-bar">
      <input
        v-model="query"
        v-on:focus="show_dropdown_fun"
        v-on:input="search"
        type="text"
        placeholder="Search"
      />
      <ul v-if="dropdown" class="results">
        <li
          v-for="result in results"
          v-bind:key="result.id"
          v-on:click="alerdt(result)"
          class="result"
        >
          {{ result.name }}
        </li>
      </ul>
    </div>
    <div class="div_to_hello">
      <div class="hello" id="chartdiv"></div>
    </div>
    <div class="hello_2" id="chartdiv_2"></div>
  </div>
</template>

<style>
.search-bar {
  position: relative;
}

.results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
}

.result {
  cursor: pointer;
  padding: 0.5em 1em;
}

.result:hover {
  background-color: #ddd;
}
.div_to_hello {
  /* width: 50%; */
  height: 505px;
}
.hello {
  /* width:400px; */
  height: 505px;
  margin: 15px;
}
.hello_2 {
  width: 100%;
  height: 500px;
}
</style>

<script>
import BackendModel from "../models/BackendModel";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default {
  data() {
    return {
      query: "",
      results: [],
      dropdown: false,
      to_search: "",
      // chart: null, // this will hold the chart instance
      apiData: [], // this will hold the data for the chart
      apiData_2: [], // this will hold the data for the chart
    };
  },
  async mounted() {
    let backendModel = new BackendModel();
    let data = await backendModel.backendRequest(
      "/Api/service/enduser/get_hp_data",
      {}
    );
    this.results = data.data.data.top_five;
    let apiData = data.data.data.g_data;
    for (let i in apiData) {
      apiData[i].date = new Date(apiData[i].date);
    }
    // console.log(apiData)
    // this.apiData_2 = data.data.data.g_data;
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    if(chart.logo){
      chart.logo.disabled = true
    }
    this.apiData = apiData;

    chart.data = this.apiData;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 20;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";

    series.tooltipText = "{valueY.value}";
    chart.cursor = new am4charts.XYCursor();
    // chart.cursor.lineX.strokeDasharray = "";
    chart.cursor.lineY.disabled = true;
    // chart.cursor.behavior = "selectX";
    // chart.cursor.events.on("cursormoved", function(ev) {
      chart.cursor.behavior = "selectX";
    chart.cursor.events.on("selectended", function (ev) {
      let range = ev.target.xRange;
      if (range) {
        let axis = ev.target.chart.xAxes.getIndex(0);
        let from = axis.positionToDate(axis.toAxisPosition(range.start));
        let to = axis.positionToDate(axis.toAxisPosition(range.end));
        alert("Selected from " + from + " to " + to);
      }
    });
    // let scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series);
    // chart.scrollbarX = scrollbarX;
    this.chart = chart;

    //   let chart_2 = am4core.create("chartdiv_2", am4charts.XYChart);

    //   chart_2.data = this.apiData_2;

    //   let dateAxis_2 = chart_2.xAxes.push(new am4charts.DateAxis());
    //   dateAxis_2.renderer.grid.template.location = 0;

    //   let valueAxis_2 = chart_2.yAxes.push(new am4charts.ValueAxis());
    //   valueAxis_2.tooltip.disabled = false;
    //   valueAxis_2.renderer.minWidth = 35;

    //   let series_2 = chart_2.series.push(new am4charts.LineSeries());
    //   series_2.dataFields.dateX = "date";
    //   series_2.dataFields.valueY = "value";

    //   // series.strokeWidth = 3;
    //   // series.fillOpacity = 0.1;
    //   // series.tooltipText = "{valueY.value}";
    //   // series.tooltip.pointerOrientation = "vertical";
    //   // series.tooltip.background.cornerRadius = 20;
    //   // series.tooltip.background.fillOpacity = 0.5;
    //   // series.tooltip.label.padding(12, 12, 12, 12);

    //   chart_2.cursor = new am4charts.XYCursor();
    //   chart_2.cursor.lineX.stroke = am4core.color("#8F3985");
    //   chart_2.cursor.lineX.strokeWidth = 4;
    //   chart_2.cursor.lineX.strokeOpacity = 0.2;
    //   chart_2.cursor.lineX.strokeDasharray = "";

    //   chart_2.cursor.lineY.disabled = true;

    //   // chart_2.cursor.behavior = "selectX";
    //   // chart_2.cursor.events.on("hover", function (ev) {
    //   //   let range = ev.target.xRange;
    //   //   if (range) {
    //   //     let axisall = ev.target.chart.xAxes.getIndex(0);
    //   //     let from = axisall.getPositionLabel(
    //   //       axisall.toAxisPosition(range.start)
    //   //     );
    //   //     let to = axisall.getPositionLabel(axisall.toAxisPosition(range.end));
    //   //     console.log("Selected from " + from + " to " + to);
    //   //   }
    //   // });

    //   let verticalLine_2 = chart_2.plotContainer.createChild(am4core.Line);
    //   verticalLine_2.stroke = am4core.color("#e81f10");
    //   verticalLine_2.strokeWidth = 1;
    //   verticalLine_2.align = "left";
    //   verticalLine_2.isMeasured = false;
    //   verticalLine_2.x = am4core.percent(0);
    //   verticalLine_2.y = 0;
    //   verticalLine_2.zIndex = -1;
    //   verticalLine_2.endLine = am4core.color("#e81f10");

    //   chart_2.cursor;
    chart.cursor;
  },

  methods: {
    show_dropdown_fun() {
      this.dropdown = !this.dropdown;
    },
    // beforeDestroy() {
    // if (this.chart) {
    //   this.chart.dispose();
    // }
    // },
    async search() {
      let backendModel = new BackendModel();
      let data = await backendModel.backendRequest(
        "/Api/service/enduser/get_companies_by_search",
        { query: this.query }
      );
      this.results = data.data.data;
      // this.results = [
      // ].filter((name) =>
      //   name.name.toLowerCase().includes(this.query.toLowerCase())
      // );
    },
    alerdt(e) {
      // console.log(e.name);
      this.dropdown = !this.dropdown;
      this.query = e.name;
    },
  },
};
</script>
